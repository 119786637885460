import React, { useMemo } from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, IconButton, Box, StepLabel, StepContent } from '@mui/material'
import { OptionSelectorComponent, ModelMenuSelectorField } from '@front/squirtle/'

import AddCircleIcon from '@mui/icons-material/AddCircle'

const Row1 = ({ schedule, formattedHours, ...props }) =>
  <Grid container item xs={12} spacing={1} alignItems='flex-end'>
    {!schedule &&
      <Grid item xs={1}>
        <IconButton onClick={() => window.open('/schedule/create', '_blank')} color='primary'>
          <AddCircleIcon fontSize='inherit' />
        </IconButton>
      </Grid>
    }
    <Grid item xs>
      <ModelMenuSelectorField
        name={'schedule'}
        label={I18n.t('schedule.label', { count: 2 })}
        model_name='schedule'
        labelKeys={['name']}
        searchMode='search'
        loadOnMount
        config={{
          forced_filter: { type: 'pricing' }
        }}
        selectFirst={false}
        required
      />
    </Grid>
    <Grid item xs={4}>
      <Typography>
        {formattedHours}
      </Typography>
    </Grid>
  </Grid >


const Row2 = ({ schedule, ...props }) =>
  <Grid container item xs={12} spacing={1} alignItems='flex-end'>
    <Grid item xs>
      <OptionSelectorComponent
        value={_.get(schedule, 'applicable_days')}
        label={I18n.t('day.label', { count: 2 })}
        multiple={true}
        options={[
          { label: I18n.t('day.monday.default'), value: 'monday' },
          { label: I18n.t('day.tuesday.default'), value: 'tuesday' },
          { label: I18n.t('day.wednesday.default'), value: 'wednesday' },
          { label: I18n.t('day.thursday.default'), value: 'thursday' },
          { label: I18n.t('day.friday.default'), value: 'friday' },
          { label: I18n.t('day.saturday.default'), value: 'saturday' },
          { label: I18n.t('day.sunday.default'), value: 'sunday' }
        ]}
        disabled={true}
      />
    </Grid>
  </Grid >

const ScheduleSection = (props) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('date.period')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 3 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
        <Row2 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(ScheduleSection)
