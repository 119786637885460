

import React, { useEffect, useMemo } from 'react'

import { hooks } from '@front/volcanion'
import ScheduleUtils from '@abra/utils/schedule'

import moment from 'moment'
import utils from '@front/volcanion/utils'

const withContainer = Component => props => {

  const schedule_id = hooks.useFieldValue(['schedule'])
  const [schedule] = hooks.useModel('schedule', [schedule_id], { single: true })
  const [schedules, searchSchedules, , { clear: clearSchedules }] = hooks.useModelSearch('schedule', 'get', {
    initial_filter: {}
  })

  useEffect(() => {
    return () => clearSchedules()
  }, [])

  const formattedHours = useMemo(() => !!schedule && `
    ${moment(utils.mergeDateTime(moment(), moment(_.get(schedule, 'start_time'), 'HH:mm:ss'))).format(`[entre] HH[h]mm`)}
    ${moment(utils.mergeDateTime(moment(), moment(_.get(schedule, 'end_time'), 'HH:mm:ss'))).format(`[et] HH[h]mm`)}
    `, [moment, utils.mergeDateTime, schedule])


  const mergedProps = {
    schedule: !!schedule && _.merge({}, ScheduleUtils.loadSchedule(schedule), { schedule_id: _.get(schedule, 'schedule_id') }),
    formattedHours
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
