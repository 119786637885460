import React, { useCallback } from 'react'
import { Typography, Grid, Box, StepLabel, StepContent, Icon } from '@mui/material'

import { I18n } from '@front/volcanion/'

import TranslationPanel from '@abra/panels/Translation'


import {
  TextField,
  MenuSelectorField,
  ModelMenuSelectorField,
  SingleOptionSelectorField
} from '@front/squirtle/'

const Row1 = (props, context) => {
  const { icons } = props || {}

  return (
    <Grid container item xs={12} spacing={2} alignItems='flex-end'>
      <Grid item xs={5} >
        <TranslationPanel {...props} />
      </Grid>
      <Grid item xs={1}>
        <MenuSelectorField
          label={I18n.t('icon')}
          name={'icon'}
          options={_.map(icons, (icon) => ({ value: icon }))}
          getOptionIcon={(option) => option.value}
          selectFirst
          required
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name={'display_index'}
          label={I18n.t('display.order')}
          type='number'
        />
      </Grid>
      <Grid item xs={1}>
        <SingleOptionSelectorField
          name='active'
          optionLabel={I18n.t('active')}
          clearOnEmpty={false}
        />
      </Grid>
      <Grid item xs={1}>
        <SingleOptionSelectorField
          name='default_required_info'
          optionLabel={I18n.t('poitype.default_required_info')}
          clearOnEmpty={false}
        />
      </Grid>
    </Grid>
  )
}

const PrimarySection = (props) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('info.general')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 5, boxShadow: 6, borderRadius: 3 }}>
      <Grid container rowSpacing={3}>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
